import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIManagerConnected {

  // --------------------- ACCORD ---------------------------------

  validateAccord (token, accord_pk) {
    const url = apiBaseUrl + `/manager-validate-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  validateAllAccords (token, accord_pks) {
    const url =  `${apiBaseUrl}/accords/validate-all`
    console.log(url)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {accordIds: accord_pks}, {'headers': headers,})
  }

  refuseAccord (token, accord_pk, refused_message) {
    const url = apiBaseUrl + `/manager-refuse-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  incompleteAccord (token, accord_pk, refused_message) {
    const url = apiBaseUrl + `/manager-incomplete-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }
  impotsIncompleteAccord (token, accord_pk, refused_message, is_avis_situation_mandatory, is_teleactualisation_mandatory, is_attestation_paiement_mandatory) {
    const url = apiBaseUrl + `/manager-impots-incomplete-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message,
      'is_avis_situation_mandatory': is_avis_situation_mandatory,
      'is_teleactualisation_mandatory': is_teleactualisation_mandatory,
      'is_attestation_paiement_mandatory': is_attestation_paiement_mandatory
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }



  // --------------------- REMBOURSEMENT ---------------------------------

  validateRemboursement (token, remboursement_pk, montant, are_frais_deplacement_validated, montant_frais_deplacement, refused_message_frais_deplacement) {
    const url = apiBaseUrl + `/manager-validate-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'are_frais_deplacement_validated': are_frais_deplacement_validated,
      'montant': montant,
      'montant_frais_deplacement': montant_frais_deplacement,
      'refused_message_frais_deplacement': refused_message_frais_deplacement
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  refuseRemboursement (token, remboursement_pk, refused_message, refused_message_frais_deplacement) {
    const url = apiBaseUrl + `/manager-refuse-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message,
      'refused_message_frais_deplacement': refused_message_frais_deplacement
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  incompleteRemboursement (token, remboursement_pk, refused_message, refused_message_frais_deplacement) {
    const url = apiBaseUrl + `/manager-incomplete-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message,
      'refused_message_frais_deplacement': refused_message_frais_deplacement
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }


  getAccordsStatistics (token, year) {
    const url = apiBaseUrl + `/manager-get-accords-statistics/` + year
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getRemboursementsStatistics (token, year) {
    const url = apiBaseUrl + `/manager-get-remboursements-statistics/` + year
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  
}
