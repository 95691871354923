<template>
  <div class="remboursementsManagerDashboardComponent">

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isRemboursementsLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#BB3634">
    </loading>


    <div class="row">
      <div class="col-12">
        <p> Par défaut, il vous est présenté la liste des demandes de remboursements à valider par un manager.</p>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="managerDashboardAllRemboursementsState-group" label="Etat de la demande " label-for="managerDashboardAllRemboursementsState-input">
          <b-form-select
            v-model="managerDashboardAllRemboursementsState"
            id="managerDashboardAllRemboursementsState-input"
            :options='remboursementStateArrayForm'>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-6 text-center align-self-center">
        <b-button
          block variant="outline-danger" class="mt-3"
          @click='filterAllRemboursementsByState'>
            <v-icon v-if="managerDashboardAllRemboursementsByStateFilter" name="lock" class="mr-2 text-danger"/>
            Filtrer toutes les demandes de remboursement selon l'état
        </b-button>
      </div>
    </div>

    <b-form v-on:submit.prevent="getAllRemboursementsByMuna()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="muna-form-group">
            <b-form-input
              id="muna-form-input"
              type="text" maxlength="11"
              v-model.trim.lazy="managerDashboardSearchRemboursementsMuna"
              @input="$v.managerDashboardSearchRemboursementsMuna.$touch()"
              :state="$v.managerDashboardSearchRemboursementsMuna.$dirty ? !$v.managerDashboardSearchRemboursementsMuna.$error : null"
              placeholder="Muna du bénéficiaire (XXXXXXXX.XX)">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            block variant="outline-danger"
            type='submit'
            :disabled="$v.managerDashboardSearchRemboursementsMuna.$invalid">
              <v-icon v-if="managerDashboardRemboursementsIsMunaFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par muna
          </b-button>
        </div>
      </div>
    </b-form>

    <b-form v-on:submit.prevent="getAllRemboursementsByBeneficiaireName()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="name-form-group">
            <b-form-input
              id="name-form-input"
              type="text" maxlength="150"
              v-model.trim.lazy="managerDashboardSearchRemboursementsName"
              @input="$v.managerDashboardSearchRemboursementsName.$touch()"
              :state="$v.managerDashboardSearchRemboursementsName.$dirty ? !$v.managerDashboardSearchRemboursementsName.$error : null"
              placeholder="Prénom ou nom du bénéficiaire">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            block variant="outline-danger"
            type='submit'
            :disabled="$v.managerDashboardSearchRemboursementsName.$invalid">
              <v-icon v-if="managerDashboardIsRemboursementsNameFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par prénom ou nom du bénéficiaire
          </b-button>
        </div>
      </div>
    </b-form>

    <b-table
      responsive class="align-middle text-center mt-4 cursor-table"
      headVariant="light" hover
      :fields="remboursementsRenderFields"
      :items="remboursements"
      @row-clicked="remboursementRowClicked"
      >
      <!--@row-clicked="beneficiaireRowClicked"-->
      <template v-slot:cell(month)="data">
        {{monthRender(data.item.month)}}
      </template>
      <template v-slot:cell(state)="data">
        {{remboursementStateRender(data.item.state)}}
      </template>
      <template v-slot:cell(state_changed_date)="data">
        {{$dayjs(data.item.state_changed_date).format('DD/MM/YYYY')}}
      </template>
      <template v-slot:cell(first_ask_validation_date)="data">
        {{$dayjs(data.item.first_ask_validation_date).format('DD/MM/YYYY')}}
      </template>
      <template v-slot:cell(conseiller_full_name)="data">
        <span v-if="data.item.conseiller_full_name">
          {{data.item.conseiller_full_name}}
        </span>
        <span v-else>
          <em>Aucun</em>
        </span>
      </template>
    </b-table>

    <b-button
      v-if="getAllRemboursementsByStateUrl && managerDashboardAllRemboursementsByStateFilter"
      block variant="outline-dark" class="mt-2"
      @click='getAllRemboursementsByState(true)'>
        Voir plus
    </b-button>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'
import renderMixins from '@/mixins/renderMixins'
import { APIConseillerConnected } from '@/api/APIConseillerConnected'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { isMuna } from '@/validators/validators'

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'remboursementsManagerDashboardComponent',
  mixins: [
    renderMixins,
    validationMixin
  ],
  data: function () {
    return {
      remboursements: [],
      remboursementsRenderFields: [
        { key: "beneficiaire_muna", label: "Muna", tdClass: 'ui-helper-center', sortable: true},
        { key: "beneficiaire_full_name", label: "Nom du bénéficiaire", tdClass: 'ui-helper-center', sortable: true},
        { key: "month", label: "Mois concerné", tdClass: 'ui-helper-center', sortable: true},
        { key: "year", label: "Année concernée", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center', sortable: true},
        { key: "first_ask_validation_date", label: "Date de dépôt", tdClass: 'ui-helper-center', sortable: true},
        { key: "state_changed_date", label: "Dernier changement d'état", tdClass: 'ui-helper-center', sortable: true},
        { key: "conseiller_full_name", label: "Conseiller attribué", tdClass: 'minWidthTd ui-helper-center', sortable: true },
      ],
      isRemboursementsLoading: true,

      // ------------ GET ALL ONGOING BENEFICIARE TO ATTRIBUTE -----------
      baseGetAllRemboursementsByStateUrlUrl: apiBaseUrl + `/conseiller-get-all-remboursements-by-state/`,
      getAllRemboursementsByStateUrl: apiBaseUrl + `/conseiller-get-all-remboursements-by-state/`,

      remboursementStateArrayForm: [
        { text: 'Créé par le bénéficiaire', value: '1CREATED' },
        { text: 'A valider par un conseiller', value: '2ONGOING' },
        { text: 'Incomplet', value: '3INCOMPLET' },
        { text: 'En doute', value: 'DOUTE' },
        { text: 'Refusé par le conseiller', value: '4CREFUSED' },
        { text: 'Validé par le conseiller', value: '5CVALIDATED' },
        { text: 'Refusé par le manager', value: '6MREFUSED' },
        { text: 'Validé par le manager', value: '7MVALIDATED' },
        { text: 'Mis en paiement', value: '8PAIEMENT' },
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    managerDashboardAllRemboursementsState: {
      get () {
        return this.$store.state.managerDashboardAllRemboursementsState
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardAllRemboursementsState', value])
      }
    },
    managerDashboardAllRemboursementsByStateFilter: {
      get () {
        return this.$store.state.managerDashboardAllRemboursementsByStateFilter
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardAllRemboursementsByStateFilter', value])
      }
    },
    managerDashboardSearchRemboursementsMuna: {
      get () {
        return this.$store.state.managerDashboardSearchRemboursementsMuna
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardSearchRemboursementsMuna', value])
      }
    },
    managerDashboardRemboursementsIsMunaFilter: {
      get () {
        return this.$store.state.managerDashboardRemboursementsIsMunaFilter
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardRemboursementsIsMunaFilter', value])
      }
    },
    managerDashboardSearchRemboursementsName: {
      get () {
        return this.$store.state.managerDashboardSearchRemboursementsName
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardSearchRemboursementsName', value])
      }
    },
    managerDashboardIsRemboursementsNameFilter: {
      get () {
        return this.$store.state.managerDashboardIsRemboursementsNameFilter
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardIsRemboursementsNameFilter', value])
      }
    },
  },
  created: function() {
    if (this.managerDashboardRemboursementsIsMunaFilter && isMuna(this.managerDashboardSearchRemboursementsMuna) && this.managerDashboardSearchRemboursementsMuna != null && this.managerDashboardSearchRemboursementsMuna != '') {
      this.getAllRemboursementsByMuna()
    }
    else if (this.managerDashboardIsRemboursementsNameFilter && this.managerDashboardSearchRemboursementsName != null && this.managerDashboardSearchRemboursementsName != '') {
      this.getAllRemboursementsByBeneficiaireName()
    }
    else {
      this.getAllRemboursementsByState()
    }
  },
  validations: {
    managerDashboardAllRemboursementsState: {
      required
    },
    managerDashboardSearchRemboursementsMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    managerDashboardSearchRemboursementsName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150),
    }
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllRemboursementsByState (isNext) {
      this.isRemboursementsLoading = true
      var url = this.getAllRemboursementsByStateUrl + this.managerDashboardAllRemboursementsState
      if (isNext) {
        url = this.getAllRemboursementsByStateUrl
      }
      apiConseillerConnected.getAllRemboursementsByState(this.token, url)
      .then((result) => {
        this.remboursements = this.remboursements.concat(result.data.results)
        this.getAllRemboursementsByStateUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ManagerDashboard getAllRemboursementsByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.managerDashboardAllRemboursementsByStateFilter = true
        this.managerDashboardRemboursementsIsMunaFilter = false
        this.managerDashboardIsRemboursementsNameFilter = false
      })
    },

    getAllRemboursementsByMuna () {
      this.isRemboursementsLoading = true
      this.remboursements = []
      apiConseillerConnected.getAllRemboursementsByMuna(this.token, this.managerDashboardSearchRemboursementsMuna)
      .then((result) => {
        this.remboursements = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ManagerDashboard getAllRemboursementsByMuna API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.managerDashboardAllRemboursementsByStateFilter = false
        this.managerDashboardRemboursementsIsMunaFilter = true
        this.managerDashboardIsRemboursementsNameFilter = false
      })
    },

    getAllRemboursementsByBeneficiaireName () {
      this.isRemboursementsLoading = true
      this.remboursements = []
      apiConseillerConnected.getAllRemboursementsByBeneficiaireName(this.token, this.managerDashboardSearchRemboursementsName)
      .then((result) => {
        this.remboursements = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ManagerDashboard getAllRemboursementsByBeneficiaireName API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.managerDashboardAllRemboursementsByStateFilter = false
        this.managerDashboardRemboursementsIsMunaFilter = false
        this.managerDashboardIsRemboursementsNameFilter = true
      })
    },

    filterAllRemboursementsByState () {
      this.getAllRemboursementsByStateUrl = this.baseGetAllRemboursementsByStateUrlUrl
      this.remboursements = []
      this.getAllRemboursementsByState(false)
    },

    remboursementRowClicked(item) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      this.$router.push('/manager-dashboard/remboursement/' + item['id'])
    },
  }
}
</script>
<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
