<template>
  <div class="ManagerDashboard" >
    <formTitleComponent />
      <div class="container-fluid">
        <h1 class="form_div_title text-center">Espace administration manager</h1>
        <div class="form_div">
          <div class="form_content">
            <b-tabs v-model="managerDashboardTabIndex" content-class="mt-3" active-nav-item-class="font-weight-bold text-white" pills>
              <b-tab >
                <template slot="title">
                  <span class="h4">Accords de principe</span>
                </template>
                <accordsManagerDashboardComponent />
              </b-tab>
              <b-tab>
                <template slot="title">
                  <span class="h4">Demandes de remboursements</span>
                </template>
                <remboursementsManagerDashboardComponent />
              </b-tab>
            </b-tabs>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import formTitleComponent from '@/components/formTitleComponent'
import accordsManagerDashboardComponent from '@/components/manager/accordsManagerDashboardComponent'
import remboursementsManagerDashboardComponent from '@/components/manager/remboursementsManagerDashboardComponent'

export default {
  name: 'ManagerDashboard',
  components: {
    formTitleComponent,
    accordsManagerDashboardComponent,
    remboursementsManagerDashboardComponent
  },
  computed: {
    managerDashboardTabIndex: {
      get () {
        return this.$store.state.managerDashboardTabIndex
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardTabIndex', value])
      }
    },
  }




}
</script>
