<template>
  <div class="accordsManagerDashboardComponent">

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isAccordsLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#BB3634">
    </loading>


    <div class="row">
      <div class="col-12">
        <p> Par défaut, il vous est présenté la liste des demandes d'accords à valider par un manager.</p>
      </div>
    </div>


    <div
      class="row mt-3"
    >
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="conseillersOptions-group" label="Conseiller " label-for="conseillersOptions-input">
          <b-form-select
            v-model="conseillerId"
            id="conseillersOptions-input"
            :options='conseillersOptions'>
          </b-form-select>
        </b-form-group>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="managerDashboardAllAccordsState-group" label="Etat de la demande " label-for="managerDashboardAllAccordsState-input">
          <b-form-select
            v-model="managerDashboardAllAccordsState"
            id="managerDashboardAllAccordsState-input"
            :options='accordStateArrayForm'>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-6 text-center align-self-center">
        <b-button
          block variant="outline-danger" class="mt-3"
          @click='filterAllAccordsByState'>
            <v-icon v-if="managerDashboardAllAccordsByStateFilter" name="lock" class="mr-2 text-danger"/>
            Filtrer toutes les demandes d'accord selon l'état et le conseiller
        </b-button>
      </div>
    </div>

    <b-form v-on:submit.prevent="getAllAccordsByMuna()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="muna-form-group">
            <b-form-input
              id="muna-form-input"
              type="text" maxlength="11"
              v-model.trim.lazy="managerDashboardSearchAccordsMuna"
              @input="$v.managerDashboardSearchAccordsMuna.$touch()"
              :state="$v.managerDashboardSearchAccordsMuna.$dirty ? !$v.managerDashboardSearchAccordsMuna.$error : null"
              placeholder="Muna du bénéficiaire (XXXXXXXX.XX)">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            block variant="outline-danger"
            type='submit'
            :disabled="$v.managerDashboardSearchAccordsMuna.$invalid">
              <v-icon v-if="managerDashboardAccordsIsMunaFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par muna
          </b-button>
        </div>
      </div>
    </b-form>

    <b-form v-on:submit.prevent="getAllAccordsByBeneficiaireName()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="name-form-group">
            <b-form-input
              id="name-form-input"
              type="text" maxlength="150"
              v-model.trim.lazy="managerDashboardSearchAccordsName"
              @input="$v.managerDashboardSearchAccordsName.$touch()"
              :state="$v.managerDashboardSearchAccordsName.$dirty ? !$v.managerDashboardSearchAccordsName.$error : null"
              placeholder="Prénom ou nom du bénéficiaire">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            block variant="outline-danger"
            type='submit'
            :disabled="$v.managerDashboardSearchAccordsName.$invalid">
              <v-icon v-if="managerDashboardIsAccordsNameFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par prénom ou nom du bénéficiaire
          </b-button>
        </div>
      </div>
    </b-form>

    <div class="mt-2">
      <b-button
        @click="validateAllAccords"
        variant="outline-success">
          <v-icon name="check-circle" class="mr-2 text-success"/>
          Valider toutes les demandes d'accord du tableau
      </b-button>
    </div>

    <b-table
      responsive class="align-middle text-center mt-4 cursor-table"
      headVariant="light" hover
      :fields="accordsRenderFields"
      :items="accords"
      @row-clicked="accordRowClicked"
      >
      <!--@row-clicked="beneficiaireRowClicked"-->
      <template v-slot:cell(state)="data">
        {{accordStateRender(data.item.state)}}
      </template>
      <template v-slot:cell(state_changed_date)="data">
        {{$dayjs(data.item.state_changed_date).format('D MMM YYYY')}}
      </template>
      <template v-slot:cell(first_ask_validation_date)="data">
        {{$dayjs(data.item.first_ask_validation_date).format('DD/MM/YYYY')}}
      </template>
      <template v-slot:cell(conseiller_full_name)="data">
        <span v-if="data.item.conseiller_full_name">
          {{data.item.conseiller_full_name}}
        </span>
        <span v-else>
          <em>Aucun</em>
        </span>
      </template>
    </b-table>

    <b-button
      v-if="getAllAccordsByStateUrl && managerDashboardAllAccordsByStateFilter"
      block variant="outline-dark" class="mt-2"
      @click='getAllAccordsByState(true)'>
        Voir plus
    </b-button>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'
import renderMixins from '@/mixins/renderMixins'
import { APIConseillerConnected } from '@/api/APIConseillerConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { isMuna } from '@/validators/validators'

const apiConseillerConnected = new APIConseillerConnected()
const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'accordsManagerDashboardComponent',
  mixins: [
    renderMixins,
    validationMixin
  ],
  data: function () {
    return {
      accords: [],
      accordsRenderFields: [
        { key: "beneficiaire_muna", label: "Muna", tdClass: 'ui-helper-center', sortable: true},
        { key: "beneficiaire_full_name", label: "Nom du bénéficiaire", tdClass: 'ui-helper-center', sortable: true},
        { key: "year", label: "Année concernée", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center' },
        { key: "first_ask_validation_date", label: "Date de dépôt", tdClass: 'ui-helper-center', sortable: true},
        { key: "conseiller_full_name", label: "Conseiller attribué", tdClass: 'minWidthTd ui-helper-center', sortable: true },
      ],
      isAccordsLoading: true,

      conseillerId: null,
      conseillers: [],

      // ------------ GET ALL ONGOING BENEFICIARE TO ATTRIBUTE -----------
      baseGetAllAccordsByStateUrlUrl: apiBaseUrl + `/conseiller-get-all-accords-by-state/`,
      getAllAccordsByStateUrl: apiBaseUrl + `/conseiller-get-all-accords-by-state/`,

      accordStateArrayForm: [
        { text: 'Créé par le bénéficiaire', value: '1CREATED' },
        { text: 'A valider par un conseiller', value: '2ONGOING' },
        { text: 'Incomplet', value: '3INCOMPLET' },
        { text: "Incomplet : avis d'imposition non suffisant", value: '3IMPOTINCOMPLET' },
        { text: 'Refusé par le conseiller', value: '4CREFUSED' },
        { text: 'Validé par le conseiller', value: '5CVALIDATED' },
        { text: 'Refusé par le manager', value: '6MREFUSED' },
        { text: 'Validé par le manager', value: '7MVALIDATED' },
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    conseillersOptions () {
      return [
        {text: "", value: null},
        ...this.conseillers.map((conseiller) => {
          return {text: `${conseiller.user.first_name} ${conseiller.user.last_name}`, value: conseiller.id}
        })
      ]
    },
    managerDashboardAllAccordsState: {
      get () {
        return this.$store.state.managerDashboardAllAccordsState
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardAllAccordsState', value])
      }
    },
    managerDashboardAllAccordsByStateFilter: {
      get () {
        return this.$store.state.managerDashboardAllAccordsByStateFilter
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardAllAccordsByStateFilter', value])
      }
    },
    managerDashboardSearchAccordsMuna: {
      get () {
        return this.$store.state.managerDashboardSearchAccordsMuna
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardSearchAccordsMuna', value])
      }
    },
    managerDashboardAccordsIsMunaFilter: {
      get () {
        return this.$store.state.managerDashboardAccordsIsMunaFilter
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardAccordsIsMunaFilter', value])
      }
    },
    managerDashboardSearchAccordsName: {
      get () {
        return this.$store.state.managerDashboardSearchAccordsName
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardSearchAccordsName', value])
      }
    },
    managerDashboardIsAccordsNameFilter: {
      get () {
        return this.$store.state.managerDashboardIsAccordsNameFilter
      },
      set (value) {
        this.$store.commit('set', ['managerDashboardIsAccordsNameFilter', value])
      }
    },
  },
  created: function() {
    this.getConseillers()
    this.init()


  },
  validations: {
    managerDashboardAllAccordsState: {
      required
    },
    managerDashboardSearchAccordsMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    managerDashboardSearchAccordsName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150),
    }
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllAccordsByState (isNext) {
      this.isAccordsLoading = true
      var url = this.getAllAccordsByStateUrl + this.managerDashboardAllAccordsState
      if (isNext) {
        url = this.getAllAccordsByStateUrl
      }
      apiConseillerConnected.getAllAccordsByState(this.token, url, this.conseillerId)
      .then((result) => {
        this.accords = this.accords.concat(result.data.results)
        this.getAllAccordsByStateUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ManagerDashboard getAllAccordsByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.managerDashboardAllAccordsByStateFilter = true
        this.managerDashboardAccordsIsMunaFilter = false
        this.managerDashboardIsAccordsNameFilter = false
      })
    },

    getAllAccordsByMuna () {
      this.isAccordsLoading = true
      this.accords = []
      apiConseillerConnected.getAllAccordsByMuna(this.token, this.managerDashboardSearchAccordsMuna)
      .then((result) => {
        this.accords = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ManagerDashboard getAllAccordsByMuna API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.managerDashboardAllAccordsByStateFilter = false
        this.managerDashboardAccordsIsMunaFilter = true
        this.managerDashboardIsAccordsNameFilter = false
      })
    },

    getAllAccordsByBeneficiaireName () {
      this.isAccordsLoading = true
      this.accords = []
      apiConseillerConnected.getAllAccordsByBeneficiaireName(this.token, this.managerDashboardSearchAccordsName)
      .then((result) => {
        this.accords = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ManagerDashboard getAllAccordsByBeneficiaireName API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.managerDashboardAllAccordsByStateFilter = false
        this.managerDashboardAccordsIsMunaFilter = false
        this.managerDashboardIsAccordsNameFilter = true
      })
    },

    getConseillers () {
      apiConseillerConnected.getConseillers(this.token)
      .then((result) => {
        this.conseillers = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getConseillers API Error : ' + String(error))
      })
    },

    filterAllAccordsByState () {
      this.getAllAccordsByStateUrl = this.baseGetAllAccordsByStateUrlUrl
      this.accords = []
      this.getAllAccordsByState(false)
    },

    init () {
      this.accords = []
      if (this.managerDashboardAccordsIsMunaFilter && isMuna(this.managerDashboardSearchAccordsMuna) && this.managerDashboardSearchAccordsMuna != null && this.managerDashboardSearchAccordsMuna != '') {
        this.getAllAccordsByMuna()
      }
      else if (this.managerDashboardIsAccordsNameFilter && this.managerDashboardSearchAccordsName != null && this.managerDashboardSearchAccordsName != '') {
        this.getAllAccordsByBeneficiaireName()
      }
      else {
        this.getAllAccordsByState()
      }
    },

    accordRowClicked(item) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      this.$router.push('/manager-dashboard/accord/' + item['id'])
    },

    validateAllAccords () {
      this.isAccordsLoading = true
      const accordIds = this.accords.map((accord) => accord.id)
      apiManagerConnected.validateAllAccords(this.token, accordIds)
      .then(() => {
        this.getAllAccordsByStateUrl = this.baseGetAllAccordsByStateUrlUrl
        this.init()
        this.$bvToast.toast(`${accordIds.length} accords validés `, {
          title: 'Accords validés',
          autoHideDelay: 5000,
          appendToast: false,
          variant: "success"
        })
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard validateAllAccords API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
      })
    }
  }
}
</script>
<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
